import React, { useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

function PandaVídeo(props) {
    const {
        url,
        allow,
        allowFullscreen,
        fetchPriority,
        borderRadius,
        iframeId,
        headCode,
    } = props

    useEffect(() => {
        if (headCode) {
            const headElements = headCode.split("\n").map((code) => {
                const element = document.createElement("div")
                element.innerHTML = code.trim()
                return element.firstChild
            })
            headElements.forEach((element) =>
                document.head.appendChild(element)
            )
            return () => {
                headElements.forEach((element) =>
                    document.head.removeChild(element)
                )
            }
        }
    }, [headCode])

    const iframeStyle = {
        border: "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }

    const containerStyle = {
        position: "relative",
        width: "100%",
        height: "100%",
        paddingTop: "56.25%", // Aspect ratio 16:9
        overflow: "hidden",
        borderRadius: `${borderRadius}px`,
        minWidth: "310px", // Largura mínima de 310px
    }

    return (
        <div style={containerStyle}>
            <iframe
                id={iframeId}
                src={url}
                style={iframeStyle}
                allow={allow.join(";")}
                allowFullScreen={allowFullscreen}
                fetchPriority={fetchPriority}
            ></iframe>
        </div>
    )
}

PandaVídeo.defaultProps = {
    url: "https://player-vz-ded14ebd-85a.tv.pandavideo.com.br/embed/?v=79cb9b0e-a64b-4485-8a44-47f36b292e4c",
    allow: [
        "accelerometer",
        "gyroscope",
        "autoplay",
        "encrypted-media",
        "picture-in-picture",
    ],
    allowFullscreen: true,
    fetchPriority: "high",
    borderRadius: 0,
    iframeId: "panda-default-id",
    headCode: "",
    codeBy: "Code by @omatusaelhorch",
}

addPropertyControls(PandaVídeo, {
    url: {
        type: ControlType.String,
        title: "URL",
        description: "URL do vídeo",
    },
    allow: {
        type: ControlType.Array,
        title: "Allow",
        control: {
            type: ControlType.String,
        },
        description: "Permissões do iframe",
    },
    allowFullscreen: {
        type: ControlType.Boolean,
        title: "Permitir Fullscreen",
        description: "Permitir modo de tela cheia",
        defaultValue: true,
    },
    fetchPriority: {
        type: ControlType.String,
        title: "Prioridade de Fetch",
        description: "Prioridade de carregamento do iframe",
    },
    borderRadius: {
        type: ControlType.Number,
        title: "Border Radius",
        description: "Raio da borda do container",
        defaultValue: 10,
        min: 0,
        max: 50,
    },
    headCode: {
        type: ControlType.String,
        title: "Performance Head",
        description: "Código para inserir no head",
        defaultValue: "",
        displayTextArea: true,
    },
    codeBy: {
        type: ControlType.String,
        title: " ",
        defaultValue: "Code by @omatusaelhorch",
        readOnly: true,
        description: "Este componente foi criado por @omatusaelhorch.",
    },
})

// Documentação
// Code by @omatusaelhorch

export default PandaVídeo
